import {isPublishedOnSite} from '@/utils/plusSite';
import type {I18NOptions} from '@/types';

export default function integrate(options: I18NOptions) {
  const {t, localizePath, site} = options;

  const links = [];

  if (isPublishedOnSite('/plus/integrate', site)) {
    links.push({
      content: t('plus:nav.integrateSystems'),
      url: localizePath('/plus/integrate'),
    });
  }

  if (isPublishedOnSite('/plus/solutions/ecommerce-automation', site)) {
    links.push({
      content: t('plus:nav.automation'),
      url: localizePath('/plus/solutions/ecommerce-automation'),
    });
  }

  if (isPublishedOnSite('/plus/solutions/headless-commerce', site)) {
    links.push({
      content: t('plus:nav.headlessCommerce'),
      url: localizePath('/plus/solutions/headless-commerce'),
    });
  }

  if (isPublishedOnSite('/plus/partners', site)) {
    links.push({
      content: t('plus:nav.partners'),
      url:
        site.locale === 'zh-CN'
          ? '/plus/partners/service?type=service&locations%5B%5D=china&locations%5B%5D=hong-kong&locations%5B%5D=hong-kong-sar&locations%5B%5D=taiwan'
          : localizePath('/plus/partners'),
    });
  }

  return links.length === 1
    ? {
        content: t('plus:nav.integrate'),
        url: localizePath('/plus/integrate'),
      }
    : {
        content: t('plus:nav.integrate'),
        navItems: links,
      };
}
