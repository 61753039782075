import type {I18NOptions} from '@/types';
import {isPublishedOnSite} from '@/utils/plusSite';

export default function manage(options: I18NOptions) {
  const {t, localizePath, site} = options;

  const links = [];

  if (isPublishedOnSite('/plus/manage', site)) {
    links.push({
      content: t('plus:nav.manageComplexity'),
      url: localizePath('/plus/manage'),
    });
  }
  if (isPublishedOnSite('/plus/solutions/ecommerce-automation', site)) {
    links.push({
      content: t('plus:nav.automation'),
      url: localizePath('/plus/solutions/ecommerce-automation'),
    });
  }
  if (isPublishedOnSite('/plus/solutions/shipping', site)) {
    links.push({
      content: t('plus:nav.shipping'),
      url: localizePath('/plus/solutions/shipping'),
    });
  }
  if (isPublishedOnSite('/plus/solutions/payments', site)) {
    links.push({
      content: t('plus:nav.payments'),
      url: localizePath('/plus/solutions/payments'),
    });
  }

  if (isPublishedOnSite('/plus/solutions/shopifyql-notebooks', site)) {
    links.push({
      content: t('plus:nav.shopifyQL'),
      url: localizePath('/plus/solutions/shopifyql-notebooks'),
    });
  }

  return links.length === 1
    ? {
        content: t('plus:nav.manage'),
        url: localizePath('/plus/manage'),
      }
    : {
        content: t('plus:nav.manage'),
        navItems: links,
      };
}
