import {useMatches} from '@remix-run/react';
import cn from 'classnames';

import type {HeaderProps} from '@/components/brochureV2/section/Header/types';
import Header from '@/components/brochureV2/section/Header/Header';
import {HeaderBackgroundColorScheme} from '@/enums';
import Anchor from '@/components/shared/Anchor/Anchor';
import {useTranslations} from '@/hooks/useTranslations';
import Button from '@/components/base/elements/Button/Button';
import useGlobalNav from '@/hooks/navigation/global';
import {usePlusNavLimitPages} from '@/hooks/experiments/usePlusNavLimitPages';

type NavKeyMapType = {
  [key: string]: string;
};

// Map pathname to nav key
const navKeyMap: NavKeyMapType = {
  pricing: 'plusPricing',
  upgrade: 'upgradeToPlus',
};

export default function HeaderLimited(props: HeaderProps) {
  const {loginUrl, loginText} = useGlobalNav();
  const matches = useMatches();
  // Get the last pathname in the matches array (the deepest nested route)
  const {pathname} = matches[matches.length - 1];
  const formattedPathName = pathname.split('/').pop();
  const pageTitleCombined = navKeyMap[formattedPathName ?? ''] ?? 'midMarket';
  const {
    background,
    CustomMobileCtaLinklist,
    CustomDesktopCtaLinklist,
    ...restProps
  } = props;
  const backgroundColorScheme =
    props?.background || HeaderBackgroundColorScheme.TransparentDark;
  const darkBackground =
    backgroundColorScheme === HeaderBackgroundColorScheme.Black ||
    backgroundColorScheme === HeaderBackgroundColorScheme.TransparentDark;

  const {t} = useTranslations();
  const contactText = t('plus:cta.contactSales');
  const contactHash = '#contact-sales';

  const {isContactSalesVariant} = usePlusNavLimitPages();

  const mobileCtaLinklist = isContactSalesVariant ? (
    <li>
      <Anchor
        className={cn('whitespace-nowrap font-bold', {
          'text-text-dark': darkBackground,
          'text-text-light': !darkBackground,
        })}
        href={contactHash}
      >
        {contactText}
      </Anchor>
    </li>
  ) : undefined;

  const desktopCtaLinklist = isContactSalesVariant ? (
    <>
      <li className="mr-8 text-base">
        <Anchor
          className={cn('whitespace-nowrap hover:underline', {
            'text-white': darkBackground,
            'text-black': !darkBackground,
          })}
          href={loginUrl}
          componentName="login"
        >
          {loginText || 'Log in'}
        </Anchor>
      </li>
      <li>
        <Button
          href={contactHash}
          componentName="contact-sales"
          size="small"
          intent="primary"
          mode={darkBackground ? 'dark' : 'light'}
        >
          {contactText}
        </Button>
      </li>
    </>
  ) : undefined;

  return (
    <Header
      {...restProps}
      CustomMobileCtaLinklist={CustomMobileCtaLinklist || mobileCtaLinklist}
      CustomDesktopCtaLinklist={CustomDesktopCtaLinklist || desktopCtaLinklist}
      CustomMobileMenuCtaLinklist={mobileCtaLinklist}
      background={backgroundColorScheme}
      secondaryNavFn={({localizePath}) => {
        const links = [
          {
            text: t('nav:plusPricing'),
            url: localizePath('/plus/pricing'),
            name: 'plus-pricing',
          },
          {
            text: t('nav:upgradeToPlus'),
            url: localizePath('/plus/upgrade'),
            name: 'plus-upgrade',
          },
          {
            text: t('nav:compareToPlus'),
            name: 'plus-compareToPlus',
            links: [
              {
                text: t('nav:shopifyVsSalesforce'),
                url: localizePath(
                  '/compare/shopify-vs-salesforce-commercecloud',
                ),
                name: 'compare-shopify-vs-salesforce-commercecloud',
              },
              {
                text: t('nav:shopifyVsBigCommerce'),
                url: localizePath('/compare/shopify-vs-bigcommerce'),
                name: 'compare-shopify-vs-bigcommerce',
              },
              {
                text: t('nav:shopifyVsSquare'),
                url: localizePath('/compare/shopify-vs-square'),
                name: 'compare-shopify-vs-square',
              },
            ],
          },
        ];

        return {
          sectionTitle: t('nav:midMarket'),
          sectionOverviewUrl: localizePath('/plus'),
          pageTitle: t(`nav:${pageTitleCombined}`),
          links,
        };
      }}
    />
  );
}
