import cn from 'classnames';
import {useEffect, useRef} from 'react';

import type {NavigationListItem} from '@/types';
import {HeaderBackgroundColorScheme} from '@/enums';
import {usePreventBodyScroll} from '@/hooks/usePreventBodyScroll';
import Anchor from '@/components/shared/Anchor/Anchor';
import {useTranslations} from '@/hooks/useTranslations';
import Caret from '@/components/brochureV2/section/Header/Caret';
import Hamburger from '@/components/brochureV2/section/Header/Hamburger';

import MobileMenuCtaLinklist from './MobileMenuCtaLinklist';

type MobileMainNavigationProps = {
  background: HeaderBackgroundColorScheme;
  logo: JSX.Element | null;
  mobileCtaLinklist: JSX.Element | null;
  CustomMobileMenuCtaLinklist?: JSX.Element | null;
  openIndex: number;
  setOpenIndex: (index: number) => void;
  isMobileMenuOpen: boolean;
  setIsMobileMenuOpen: (isOpen: boolean) => void;
  setActiveDesktopMainNavigationIndex: (index: number) => void;
  setActiveMobileMainNavigationIndex: (index: number) => void;
  mainNav: NavigationListItem[];
};

export default function MobileMainNavigation({
  logo,
  mobileCtaLinklist,
  CustomMobileMenuCtaLinklist,
  openIndex,
  setOpenIndex,
  isMobileMenuOpen,
  background: bg,
  setIsMobileMenuOpen,
  setActiveDesktopMainNavigationIndex,
  setActiveMobileMainNavigationIndex,
  mainNav,
}: MobileMainNavigationProps) {
  const {t} = useTranslations();
  const background: HeaderBackgroundColorScheme =
    bg || HeaderBackgroundColorScheme.TransparentLight;
  const mobileNavRef = useRef<HTMLDivElement>(null);
  const isMobileMenuOpenRef = useRef(isMobileMenuOpen);
  usePreventBodyScroll(isMobileMenuOpen);

  useEffect(() => {
    isMobileMenuOpenRef.current = isMobileMenuOpen;
  }, [isMobileMenuOpen]);

  useEffect(() => {
    if (!mobileNavRef.current) return;

    const mobileNavElement = mobileNavRef.current;
    const isOpen = isMobileMenuOpenRef.current;

    const hideMobileNav = () => {
      if (isOpen) return;
      mobileNavElement.style.visibility = 'hidden';
    };
    const showMobileNav = () => {
      if (!isOpen) return;
      mobileNavElement.style.visibility = 'visible';
    };

    mobileNavElement.addEventListener('transitionstart', showMobileNav);
    mobileNavElement.addEventListener('transitionend', hideMobileNav);

    return () => {
      mobileNavElement.removeEventListener('transitionstart', showMobileNav);
      mobileNavElement.removeEventListener('transitionend', hideMobileNav);
    };
  });

  const darkBackground =
    background === HeaderBackgroundColorScheme.Black ||
    background === HeaderBackgroundColorScheme.TransparentDark;
  const lightBackground =
    background === HeaderBackgroundColorScheme.White ||
    background === HeaderBackgroundColorScheme.TransparentLight;

  // *************************
  // **  Default Components **
  // *************************
  const mobileMenuCtaLinklist =
    CustomMobileMenuCtaLinklist === undefined ? (
      <MobileMenuCtaLinklist darkBackground={darkBackground} />
    ) : (
      CustomMobileMenuCtaLinklist
    );

  return (
    <div
      ref={mobileNavRef}
      id="MobileDrawer"
      className={cn(
        'fixed top-0 bottom-0 nav:hidden flex w-full flex-col',
        'transition-opacity ease-[cubic-bezier(0.87,0,0.13,1)] duration-300 motion-reduce:transition-none',
        {
          'bg-black text-white': darkBackground,
          'bg-white text-black': lightBackground,
          'invisible opacity-0': !isMobileMenuOpen,
          'visible opacity-100': isMobileMenuOpen,
        },
      )}
      data-component-name="mobile-navigation-drawer"
    >
      <div
        className={cn('transition-colors duration-700', {
          'bg-white': isMobileMenuOpen && !darkBackground,
          'bg-black': isMobileMenuOpen && darkBackground,
          'border-b border-black/20':
            background === HeaderBackgroundColorScheme.TransparentLight ||
            background === HeaderBackgroundColorScheme.White,
          'border-b border-white/20':
            background === HeaderBackgroundColorScheme.TransparentDark ||
            background === HeaderBackgroundColorScheme.Black,
        })}
      >
        <div className="h-global-header container flex items-center translate-y-[-1px]">
          {logo && logo}
          <ul className="nav:hidden mr-[-15px] ml-auto flex items-center">
            {mobileCtaLinklist && mobileCtaLinklist}
            <li>
              <Hamburger
                background={background}
                isActive={isMobileMenuOpen}
                onToggle={() => {
                  setIsMobileMenuOpen(!isMobileMenuOpen);
                  setActiveDesktopMainNavigationIndex(-1);
                  setActiveMobileMainNavigationIndex(-1);
                }}
              />
            </li>
          </ul>
        </div>
      </div>
      {mainNav.length > 0 && (
        <nav
          id="MobileNavigation"
          aria-label={t('global:ariaLabels.header.mainNav')}
          itemScope
          itemType="https://schema.org/SiteNavigationElement"
          className="no-scrollbar overflow-y-auto sm:mt-10 mt-8"
        >
          <ul className="mx-4 mb-8 sm:mb-10">
            {mainNav.map((navItem, navItemIndex) => (
              <li
                key={`${navItem.content || 'content'}${navItemIndex}`}
                className={cn('mb-6 sm:mb-8 text-base', {
                  'is-active': navItemIndex === openIndex,
                })}
              >
                {navItem.url ? (
                  <Anchor
                    href={navItem.url}
                    className={cn(
                      'text-xl font-bold sm:text-2xl lg:font-normal',
                      {
                        'text-black': !darkBackground,
                        'text-white': darkBackground,
                      },
                    )}
                  >
                    {navItem.content}
                  </Anchor>
                ) : (
                  <>
                    <div
                      className={cn(
                        "sticky top-[-1px] after:absolute after:block after:h-8 after:w-full after:bg-gradient-to-b after:to-transparent after:content-['']",
                        {
                          'bg-black after:from-black': darkBackground,
                        },
                        {
                          'bg-white after:from-white': lightBackground,
                        },
                      )}
                    >
                      <button
                        type="button"
                        onClick={() =>
                          navItemIndex === openIndex
                            ? setOpenIndex(-1)
                            : setOpenIndex(navItemIndex)
                        }
                        className="flex min-h-[45px] w-max items-center"
                        data-component-name={`${navItem.content}-toggle-${
                          navItemIndex === openIndex ? 'close' : 'open'
                        }`}
                        aria-expanded={navItemIndex === openIndex}
                        aria-controls={
                          navItem.content
                            ? `${navItem.content}MobileMenu`
                            : `MobileMenu${navItemIndex}`
                        }
                      >
                        <span className="mr-3 text-xl font-bold sm:text-2xl lg:font-normal">
                          {navItem.content}
                        </span>
                        <div className="inline-block">
                          <Caret
                            isActive={navItemIndex === openIndex}
                            background={background}
                          />
                        </div>
                      </button>
                    </div>
                    <div className="overflow-hidden">
                      <div
                        id={
                          navItem.content
                            ? `${navItem.content}MobileMenu`
                            : `MobileMenu${navItemIndex}`
                        }
                        className={cn(
                          'transition-all duration-500 ease-out motion-reduce:transition-none',
                          {
                            '-mb-[2000px] max-h-0 invisible':
                              navItemIndex !== openIndex,
                          },
                          {
                            'max-h-[10000px] visible':
                              navItemIndex === openIndex,
                          },
                        )}
                      >
                        {navItem.navItems?.map((subNavItem, idx) => (
                          <div
                            key={`${subNavItem.content || 'content'}${idx}`}
                            className="pl-3 mt-3 first:mt-4"
                          >
                            {subNavItem.content && (
                              <Anchor
                                className="font-bold inline-block text-lg sm:text-xl"
                                href={subNavItem.url}
                              >
                                {subNavItem.content}
                              </Anchor>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </>
                )}
              </li>
            ))}
          </ul>
        </nav>
      )}
      {mobileMenuCtaLinklist && (
        <ul
          className={cn(
            'border-shade-30 relative mt-auto flex flex-col sm:flex-row sm:gap-5 border-t-[1px] py-3.5 sm:py-4 px-4 text-center',
            "before:absolute before:top-[-1px] before:left-0 before:block before:h-12 before:w-full before:-translate-y-12 before:bg-gradient-to-b before:from-transparent before:content-['']",
            {
              'before:to-black': darkBackground,
            },
            {
              'before:to-white': lightBackground,
            },
          )}
          data-component-name="mobile-navigation-drawer-lower"
        >
          {mobileMenuCtaLinklist}
        </ul>
      )}
    </div>
  );
}
