import type {I18NOptions} from '@/types';
import {isPublishedOnSite} from '@/utils/plusSite';

export default function sell(options: I18NOptions) {
  const {t, localizePath, site} = options;

  const links = [];
  if (isPublishedOnSite('/plus/sell', site)) {
    links.push({
      content: t('plus:nav.sellEverywhere'),
      url: localizePath('/plus/sell'),
    });
  }
  if (isPublishedOnSite('/plus/solutions/enterprise-online-store', site)) {
    links.push({
      content: t('plus:nav.onlineStore'),
      url: localizePath('/plus/solutions/enterprise-online-store'),
    });
  }
  if (isPublishedOnSite('/plus/solutions/international-ecommerce', site)) {
    links.push({
      content: t('plus:nav.internationalCommerce'),
      url: localizePath('/plus/solutions/international-ecommerce'),
    });
  }
  if (isPublishedOnSite('/plus/solutions/omnichannel-commerce', site)) {
    links.push({
      content: t('plus:nav.omnichannelCommerce'),
      url: localizePath('/plus/solutions/omnichannel-commerce'),
    });
  }
  if (isPublishedOnSite('/plus/solutions/headless-commerce', site)) {
    links.push({
      content: t('plus:nav.headlessCommerce'),
      url: localizePath('/plus/solutions/headless-commerce'),
    });
  }
  if (
    isPublishedOnSite(
      '/plus/solutions/ecommerce-campaigns-flash-sale-automation',
      site,
    )
  ) {
    links.push({
      content: t('plus:nav.campaignsFlashSales'),
      url: localizePath(
        '/plus/solutions/ecommerce-campaigns-flash-sale-automation',
      ),
    });
  }
  if (isPublishedOnSite('/plus/solutions/retail-and-point-of-sale', site)) {
    links.push({
      content: t('plus:nav.retailPos'),
      url: localizePath('/plus/solutions/retail-and-point-of-sale'),
    });
  }
  if (isPublishedOnSite('/plus/solutions/b2b-ecommerce', site)) {
    links.push({
      content: t('plus:nav.b2bEcommerce'),
      url: localizePath('/plus/solutions/b2b-ecommerce'),
    });
  }

  return links.length === 1
    ? {
        content: t('plus:nav.sell'),
        url: localizePath('/plus/sell'),
      }
    : {
        content: t('plus:nav.sell'),
        navItems: links,
      };
}
