import {EXP_PLUS_NAV_LIMIT_PAGES} from '@/experiments';
import {useExperiment} from '@/hooks/useExperiment';
import {useSiteData} from '@/hooks/useSiteData';

export const usePlusNavLimitPages = () => {
  const variant = useExperiment(EXP_PLUS_NAV_LIMIT_PAGES);
  const {site} = useSiteData();

  const isContactSalesVariant = variant === 'treatment';

  const isPlusNavLimitPagesExperiment =
    ['en', 'en-CA'].includes(site.locale) &&
    (isContactSalesVariant || variant === 'treatment_2');

  /** conditional style for the plus nav experiment `treatment` group */
  const cs = (style: string) => {
    return isPlusNavLimitPagesExperiment ? style : '';
  };

  return {
    isPlusNavLimitPagesExperiment,
    isContactSalesVariant,
    cs,
  };
};
