import {useCallback, useEffect, useRef, useState} from 'react';

import {twMerge} from '@/stylesheets/twMerge';
import Hamburger from '@/components/brochureV2/section/Header/Hamburger';
import type {HeaderProps} from '@/components/brochureV2/section/Header/types';
import type {SecondaryNavigationType} from '@/components/base/sections/Header/modules/SecondaryNavigation/SecondaryNavigation';
import SecondaryNavigation from '@/components/base/sections/Header/modules/SecondaryNavigation/SecondaryNavigation';
import {HeaderBackgroundColorScheme, ScrollDirection} from '@/enums';
import sell from '@/hooks/plus/navigation/header/sell';
import manage from '@/hooks/plus/navigation/header/manage';
import integrate from '@/hooks/plus/navigation/header/integrate';
import platform from '@/hooks/plus/navigation/header/platform';
import type {NavOptions} from '@/hooks/navigation/types';
import useIntersectionObserver from '@/hooks/useIntersectionObserver';
import {useKeyPress} from '@/hooks/useKeyPress';
import {useMediaQuery} from '@/hooks/useMediaQuery';
import {useScrollDirection} from '@/hooks/useScrollDirection';
import {useScrollThresholds} from '@/hooks/useScrollThresholds';
import {useTranslations} from '@/hooks/useTranslations';
import type {I18NOptions} from '@/types';
import {
  headerContainer2Styles,
  headerContainerStyles,
  headerStyles,
  primaryNavigationListStyles,
  secondaryNavigationContainerStyles,
} from '@/components/brochureV2/section/Header/styles';
import {isPublishedOnSite} from '@/utils/plusSite';
import ShopifyLogo from '@/components/base/elements/Logo/ShopifyLogo';
import {
  ShopifyLogoColor,
  ShopifyLogoTheme,
} from '@/components/base/elements/Logo/types';
import {usePlusNavLimitPages} from '@/hooks/experiments/usePlusNavLimitPages';

import DesktopCtaLinklist from './DesktopCtaLinklist';
import DesktopMainNavigation from './DesktopMainNavigation';
import MobileCtaLinklist from './MobileCtaLinklist';
import MobileMainNavigation from './MobileMainNavigation';
import HeaderLimited from './HeaderLimited';

export {HeaderProps};

const defaultMainNav = ({t, localizePath, site}: I18NOptions) => {
  const gaEvent = 'Main Nav';
  const options: NavOptions = {t, localizePath, site, gaEvent};

  const nav = [sell(options), manage(options), integrate(options)];

  if (isPublishedOnSite('/plus/migration', site)) {
    nav.push({
      content: t('plus:nav.migrate'),
      url: localizePath('/plus/migration'),
    });
  }

  nav.push(platform(options));

  // we show pricing in non-english locales for some reason
  if (site.languageCode !== 'en') {
    nav.push({
      content: t('plus:nav.pricing'),
      url: localizePath('/plus/pricing'),
    });
  }

  if (isPublishedOnSite('/enterprise/blog', site)) {
    nav.push({
      content: t('plus:nav.blog'),
      url: localizePath('/enterprise/blog'),
    });
  }

  return nav;
};

export default function Header({
  CustomDesktopCtaLinklist,
  CustomMobileCtaLinklist,
  CustomMobileMenuCtaLinklist,
  mainNavFn,
  secondaryNavFn,
  background,
  className,
  logo: logoProp,
  modalHash,
}: HeaderProps & {modalHash?: string}) {
  const translations = useTranslations();
  const {localizePath} = translations;
  const mainNav = mainNavFn
    ? mainNavFn(translations)
    : defaultMainNav(translations);
  const secondaryNav = secondaryNavFn
    ? ({
        ...secondaryNavFn(translations),
        moreText: translations.t('nav:more'),
      } as SecondaryNavigationType)
    : undefined;
  const hasSecondaryNav =
    (secondaryNav?.links && secondaryNav.links.length > 0) ?? false;
  const backgroundColorScheme = background || HeaderBackgroundColorScheme.Black;
  const darkBackground =
    backgroundColorScheme === HeaderBackgroundColorScheme.Black ||
    backgroundColorScheme === HeaderBackgroundColorScheme.TransparentDark;

  const [isPrimaryNavigationInViewport, setIsPrimaryNavigationInViewport] =
    useState(true);

  const [
    activeDesktopMainNavigationIndex,
    setActiveDesktopMainNavigationIndex,
  ] = useState(-1);
  const [activeMobileMainNavigationIndex, setActiveMobileMainNavigationIndex] =
    useState(-1);
  const [isFixed, setIsFixed] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const mainHeaderRef = useRef<HTMLDivElement>(null);
  const mainHeaderInnerRef = useRef<HTMLDivElement>(null);
  const primaryNavigationOuterContainerRef = useRef<HTMLDivElement>(null);
  const isMobile = useMediaQuery('(max-width: 1119px)');
  const wasMobileRef = useRef<boolean | null>(null);

  const scrollDirection = useScrollDirection();
  const scrollThreshold = useScrollThresholds([0, 1, 71, 141]);

  // The menu should close when Escape is pressed
  useKeyPress('Escape', () => {
    setIsMobileMenuOpen(false);
    setActiveDesktopMainNavigationIndex(-1);
    setActiveMobileMainNavigationIndex(-1);
  });

  const memoizedHandleIntersectCallback = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsPrimaryNavigationInViewport(true);
        } else {
          setIsPrimaryNavigationInViewport(false);
        }
      });
    },
    [setIsPrimaryNavigationInViewport],
  );

  let options = {
    root: null,
    rootMargin: '0px',
    threshold: 0,
  };

  useIntersectionObserver(
    primaryNavigationOuterContainerRef,
    memoizedHandleIntersectCallback,
    options,
  );

  useEffect(() => {
    if (scrollThreshold === 0) {
      setIsFixed(false);
    } else if (hasSecondaryNav && isPrimaryNavigationInViewport) {
      setIsFixed(false);
    } else if (hasSecondaryNav && !isPrimaryNavigationInViewport) {
      setIsFixed(true);
    } else if (!hasSecondaryNav && scrollThreshold > 0) {
      setIsFixed(true);
    }
  }, [scrollThreshold, hasSecondaryNav, isPrimaryNavigationInViewport]);

  // Once scrolled the background color of the header changes if initially transparent
  useEffect(() => {
    if (scrollThreshold > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  }, [scrollThreshold]);

  // When transitioning between mobile and desktop breaskpoints, the menu should be closed
  useEffect(() => {
    if (isMobile === null) return;

    if (isMobile && !wasMobileRef.current) {
      setActiveDesktopMainNavigationIndex(-1);
      setActiveMobileMainNavigationIndex(-1);
    } else if (!isMobile && wasMobileRef.current) {
      setIsMobileMenuOpen(false);
      setActiveDesktopMainNavigationIndex(-1);
      setActiveMobileMainNavigationIndex(-1);
    }

    wasMobileRef.current = isMobile;
  }, [isMobile]);

  // *************************
  // **  Default Components **
  // *************************
  const logo = logoProp || (
    <div className="nav:w-[127px] mr-12 w-[98px] shrink-0 text-white">
      <a href={localizePath('/plus')}>
        <ShopifyLogo
          logoTheme={
            darkBackground ? ShopifyLogoTheme.White : ShopifyLogoTheme.Black
          }
          textColor={
            darkBackground ? ShopifyLogoColor.White : ShopifyLogoColor.Black
          }
          logoWithText={true}
          height="32"
        />
      </a>
    </div>
  );
  const desktopCtaLinklist =
    CustomDesktopCtaLinklist === undefined ? (
      <DesktopCtaLinklist
        darkBackground={darkBackground}
        modalHash={modalHash}
      />
    ) : (
      CustomDesktopCtaLinklist
    );
  const mobileCtaLinklist =
    CustomMobileCtaLinklist === undefined ? (
      <MobileCtaLinklist
        darkBackground={darkBackground}
        modalHash={modalHash}
      />
    ) : (
      CustomMobileCtaLinklist
    );

  const hasHamburger =
    mainNav.length > 0 || CustomMobileMenuCtaLinklist !== null;

  const {isPlusNavLimitPagesExperiment} = usePlusNavLimitPages();

  if (isPlusNavLimitPagesExperiment) {
    return (
      <HeaderLimited
        CustomDesktopCtaLinklist={CustomDesktopCtaLinklist}
        CustomMobileCtaLinklist={CustomMobileCtaLinklist}
        CustomMobileMenuCtaLinklist={CustomMobileMenuCtaLinklist}
        background={backgroundColorScheme}
        className={className}
        mainNavFn={mainNavFn}
      />
    );
  }

  return (
    <header
      ref={mainHeaderRef}
      className={twMerge(headerStyles({isFixed, hasSecondaryNav}), className)}
      data-component-name="global-nav"
      data-has-secondary-navigation={hasSecondaryNav}
    >
      <div
        ref={mainHeaderInnerRef}
        className={twMerge(
          headerContainerStyles({
            backgroundColorScheme,
            hasSecondaryNav,
            isDesktopMenuOpen: activeDesktopMainNavigationIndex >= 0,
            isFixed,
            isMobileMenuOpen,
            isScrolled,
            isThresholdPassed: scrollThreshold < 72,
            scrollDirection,
          }),
        )}
      >
        <div
          className={twMerge(
            headerContainer2Styles({
              backgroundColorScheme,
              hasSecondaryNav,
              isDesktopMenuOpen: activeDesktopMainNavigationIndex >= 0,
              isFixed,
              isMobileMenuOpen,
            }),
            'border-b-0',
          )}
        >
          <div
            ref={primaryNavigationOuterContainerRef}
            className="h-global-header container flex items-center"
          >
            {logo}
            <DesktopMainNavigation
              mainNav={mainNav}
              openIndex={activeDesktopMainNavigationIndex}
              setOpenIndex={setActiveDesktopMainNavigationIndex}
              background={backgroundColorScheme}
            />
            <div className="ml-auto">
              {Boolean(desktopCtaLinklist) && (
                <ul className="nav:flex hidden items-center">
                  {desktopCtaLinklist}
                </ul>
              )}
              <ul
                className={twMerge(primaryNavigationListStyles({hasHamburger}))}
              >
                {mobileCtaLinklist}
                {hasHamburger && (
                  <li>
                    <Hamburger
                      background={backgroundColorScheme}
                      isActive={isMobileMenuOpen}
                      onToggle={() => {
                        setIsMobileMenuOpen(!isMobileMenuOpen);
                        setActiveDesktopMainNavigationIndex(-1);
                        setActiveMobileMainNavigationIndex(-1);
                      }}
                    />
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
        {hasSecondaryNav && (
          <div
            className={twMerge(
              secondaryNavigationContainerStyles({
                backgroundColorScheme,
                hasSecondaryNav,
                isDesktopMenuOpen: activeDesktopMainNavigationIndex >= 0,
                isFixed,
                isMobileMenuOpen,
              }),
            )}
          >
            <div className="h-global-header container flex items-center">
              <SecondaryNavigation
                secondaryNav={secondaryNav}
                mode={darkBackground ? 'dark' : 'light'}
                showGlobals={
                  isScrolled &&
                  isFixed &&
                  scrollDirection === ScrollDirection.Down
                }
              />
            </div>
          </div>
        )}
      </div>
      <MobileMainNavigation
        logo={logo as JSX.Element}
        mobileCtaLinklist={mobileCtaLinklist}
        CustomMobileMenuCtaLinklist={CustomMobileMenuCtaLinklist}
        openIndex={activeMobileMainNavigationIndex}
        setOpenIndex={setActiveMobileMainNavigationIndex}
        isMobileMenuOpen={isMobileMenuOpen}
        background={backgroundColorScheme}
        setIsMobileMenuOpen={setIsMobileMenuOpen}
        setActiveDesktopMainNavigationIndex={
          setActiveDesktopMainNavigationIndex
        }
        setActiveMobileMainNavigationIndex={setActiveMobileMainNavigationIndex}
        mainNav={mainNav}
      />
    </header>
  );
}
