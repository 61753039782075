import type {I18NOptions} from '@/types';
import {isPublishedOnSite} from '@/utils/plusSite';

export default function platform(options: I18NOptions) {
  const {t, localizePath, site} = options;

  const links = [];

  if (isPublishedOnSite('/plus/platform', site)) {
    links.push({
      content: t('plus:nav.plusPlatform'),
      url: localizePath('/plus/platform'),
    });
  }

  if (isPublishedOnSite('/case-studies', site)) {
    links.push({
      content: t('plus:nav.caseStudies'),
      url: localizePath('/case-studies'),
    });
  }

  if (isPublishedOnSite('/plus/pricing', site)) {
    links.push({
      content: t('plus:nav.pricing'),
      url: localizePath('/plus/pricing'),
    });
  }

  if (isPublishedOnSite('/plus/upgrade', site)) {
    links.push({
      content: t('plus:nav.upgradeToPlus'),
      url: localizePath('/plus/upgrade'),
    });
  }

  return links.length === 1
    ? {
        content: t('plus:nav.platform'),
        url: localizePath('/plus/platform'),
      }
    : {
        content: t('plus:nav.platform'),
        navItems: links,
      };
}
