import type {Site} from '@data/types';
import {Page} from '@/utils/server/Page';
import plusFallbackPages from '@data/plusFallbackPages.json';

export const shouldFallback = (path: string, site: Site): boolean => {
  const plusFallbacks = plusFallbackPages as {[path: string]: string[]};
  for (const fallbackPath in plusFallbacks) {
    if (path.startsWith(fallbackPath)) {
      if (plusFallbacks[fallbackPath].includes(site.locale)) {
        return true;
      }
    }
  }
  return false;
};

export function isPublishedOnSite(path: string, site: Site): boolean {
  const page = Page.byPath(path);

  return page?.isPublishedOnSite(site) || shouldFallback(path, site);
}
