import cn from 'classnames';

import Anchor from '@/components/shared/Anchor/Anchor';
import Button from '@/components/base/elements/Button/Button';
import {useContactUsNavTextToBtn} from '@/hooks/experiments/useContactUsNavTextToBtn';
import {useTranslations} from '@/hooks/useTranslations';

interface Props {
  darkBackground: boolean;
  modalHash?: string;
}

export default function MobileCtaLinklist({
  darkBackground,
  modalHash = '#contact-sales',
}: Props) {
  const {t} = useTranslations();
  const isExpContactUsTextToBtn = useContactUsNavTextToBtn();
  const contactText = t('plus:cta.contact');
  return (
    <li>
      {isExpContactUsTextToBtn ? (
        <Button
          href={modalHash}
          componentName="contact-sales"
          size="small"
          mode="dark"
          className="whitespace-nowrap"
        >
          {contactText}
        </Button>
      ) : (
        <Anchor
          className={cn('whitespace-nowrap font-bold', {
            'text-text-dark': darkBackground,
            'text-text-light': !darkBackground,
          })}
          href={modalHash}
        >
          {contactText}
        </Anchor>
      )}
    </li>
  );
}
