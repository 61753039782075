import cn from 'classnames';
import {createRef, useEffect, useRef, useState} from 'react';

import Caret from '@/components/brochureV2/section/Header/Caret';
import Anchor from '@/components/shared/Anchor/Anchor';
import {HeaderBackgroundColorScheme} from '@/enums';
import {useKeyPress} from '@/hooks/useKeyPress';
import {useOnClickOutside} from '@/hooks/useOnClickOutside';
import useReducedMotion from '@/hooks/useReducedMotion';
import {useTranslations} from '@/hooks/useTranslations';
import type {NavigationListItem} from '@/types';

interface DesktopMainNavigationProps {
  background?: HeaderBackgroundColorScheme;
  openIndex: number;
  setOpenIndex: (index: number) => void;
  mainNav: NavigationListItem[];
}

export default function DesktopMainNavigation({
  openIndex,
  setOpenIndex,
  background,
  mainNav,
}: DesktopMainNavigationProps) {
  const {t} = useTranslations();
  const prefersReducedMotion = useReducedMotion(false);
  const prevOpenIndex = useRef(-1);
  const [focusedIndex, setFocusedIndex] = useState(-1);

  const subNavActivatorRefs: any = useRef<null | HTMLButtonElement>();
  subNavActivatorRefs.current = mainNav.map(
    (_: any, i: number) =>
      subNavActivatorRefs?.current?.[i] ??
      createRef<null | HTMLButtonElement>(),
  ) ?? [createRef<null | HTMLButtonElement>()];

  const subNavRefs: any = useRef<null | HTMLDivElement>();
  subNavRefs.current = mainNav.map(
    (_: any, i: number) =>
      subNavRefs?.current?.[i] ?? createRef<null | HTMLDivElement>(),
  ) ?? [createRef<null | HTMLDivElement>()];

  const darkBackground =
    background === HeaderBackgroundColorScheme.Black ||
    background === HeaderBackgroundColorScheme.TransparentDark;
  const lightBackground =
    background === HeaderBackgroundColorScheme.White ||
    background === HeaderBackgroundColorScheme.TransparentLight;

  const closeNavigationHandler = () => {
    if (openIndex < 0) return;

    setOpenIndex(-1);
    subNavActivatorRefs?.current[openIndex]?.current.focus();
  };

  useEffect(() => {
    if (openIndex === -1) setFocusedIndex(-1);
  }, [openIndex]);

  const focusNextSubNavItem = (event: KeyboardEvent) => {
    if (openIndex === -1 || !subNavRefs.current[openIndex]) return;
    event.preventDefault();
    const newFocusedIndex = focusedIndex + 1;

    const subNavItems =
      subNavRefs.current[openIndex].current.querySelectorAll('a');
    if (!subNavItems.length || newFocusedIndex >= subNavItems.length) return;

    subNavItems[newFocusedIndex].focus();
    if (newFocusedIndex < subNavItems.length) setFocusedIndex(newFocusedIndex);
  };

  const focusPreviousSubNavItem = (event: KeyboardEvent) => {
    if (openIndex === -1 || !subNavRefs.current[openIndex] || focusedIndex < 1)
      return;
    event.preventDefault();
    const newFocusedIndex = focusedIndex - 1;

    const subNavItems =
      subNavRefs.current[openIndex].current.querySelectorAll('a');
    if (!subNavItems.length || focusedIndex < 1) return;

    subNavItems[newFocusedIndex].focus();
    setFocusedIndex(newFocusedIndex);
  };

  useKeyPress('Escape', closeNavigationHandler);
  useKeyPress('ArrowDown', focusNextSubNavItem);
  useKeyPress('ArrowRight', focusNextSubNavItem);
  useKeyPress('ArrowUp', focusPreviousSubNavItem);
  useKeyPress('ArrowLeft', focusPreviousSubNavItem);

  useOnClickOutside(closeNavigationHandler);

  useEffect(() => {
    prevOpenIndex.current = openIndex;

    subNavRefs.current.forEach((subNav: any) => {
      if (subNav.current) subNav.current.scrollTop = 0;
    });
  }, [openIndex]);

  return (
    <nav
      className="nav:flex hidden h-full w-full"
      aria-label={t('global:ariaLabels.header.mainNav')}
      itemScope
      itemType="https://schema.org/SiteNavigationElement"
      data-click-outside="dismiss"
    >
      {mainNav.length > 0 && (
        <ul className="flex h-full w-full">
          {mainNav.map((navItem, navItemIndex: number) => (
            <li
              key={navItem.content}
              className={cn('mr-8 text-base', {
                'ml-auto': navItemIndex === mainNav.length - 2,
              })}
            >
              {navItem.url ? (
                <div className="flex h-full">
                  <Anchor
                    className={cn(
                      'flex items-center hover:underline font-bold text-[0.9375rem]',
                      {
                        'text-black hover:text-black': lightBackground,
                        'text-white hover:text-white': darkBackground,
                      },
                    )}
                    href={navItem.url}
                  >
                    {navItem.content}
                  </Anchor>
                </div>
              ) : (
                <>
                  <div
                    className={cn(
                      [
                        'relative',
                        'flex',
                        'h-full',
                        'after:content[""]',
                        'after:block',
                        'after:h-[3px]',
                        'after:w-full',
                        'after:absolute',
                        'after:bottom-0',
                        'after:scale-0',
                        'after:origin-left',
                        'after:transition-transform',
                        'after:duration-[350ms]',
                        'after:ease-[bezier(0.66, 0.66, 0.34, 1.00)]',
                        'after:motion-reduce:transition-none',
                      ],
                      {
                        'after:scale-100': navItemIndex === openIndex,
                      },
                      {
                        'hover:underline': navItemIndex !== openIndex,
                      },
                      {
                        'after:bg-black text-black': lightBackground,
                      },
                      {
                        'after:bg-white text-white': darkBackground,
                      },
                    )}
                  >
                    <button
                      ref={subNavActivatorRefs.current[navItemIndex]}
                      type="button"
                      className="bg-transparent font-bold"
                      onClick={() =>
                        navItemIndex === openIndex
                          ? setOpenIndex(-1)
                          : setOpenIndex(navItemIndex)
                      }
                      data-component-name={`${navItem.content}-toggle-${
                        navItemIndex === openIndex ? 'close' : 'open'
                      }`}
                      aria-controls={
                        navItem.content
                          ? `${navItem.content}DesktopMenu`
                          : `DesktopMenu${navItemIndex}`
                      }
                      aria-expanded={navItemIndex === openIndex}
                    >
                      <span className="mr-3">{navItem.content}</span>
                      <Caret
                        isActive={navItemIndex === openIndex}
                        background={background}
                      />
                    </button>
                  </div>
                  <ul
                    ref={subNavRefs.current[navItemIndex]}
                    id={
                      navItem.content
                        ? `${navItem.content}DesktopMenu`
                        : `DesktopMenu${navItemIndex}`
                    }
                    className={cn(
                      'absolute top-[80px] z-[21]',
                      'py-2 min-w-[240px]',
                      'transition-transform duration-500 ease-[cubic-bezier(0.26,1.00,0.48,1.00)] motion-reduce:transition-none',
                      'bg-white text-black rounded-lg shadow-md whitespace-nowrap transition-all box-content',
                      {
                        'transition-[opacity] duration-[277ms]':
                          prevOpenIndex.current > -1,
                      },
                      {'opacity-0 invisible': navItemIndex !== openIndex},
                      {
                        'opacity-100 visible pointer-events-auto':
                          navItemIndex === openIndex,
                      },
                      {
                        '-translate-y-[10px]':
                          openIndex === -1 && !prefersReducedMotion,
                      },
                      {
                        'translate-y-0':
                          navItemIndex === openIndex && !prefersReducedMotion,
                      },
                    )}
                  >
                    {navItem.navItems?.map((subNavItem, idx) => (
                      <li
                        key={`${subNavItem.content || 'content'}${idx}`}
                        style={{transitionDelay: `${idx * 30}ms`}}
                        className={cn(
                          'text-md transition-all duration-300 motion-reduce:transition-none opacity-0 motion-reduce:opacity-100',
                          {
                            'after:content-[""] after:w-[calc(100%-2rem)] after:absolute after:left-4 after:-bottom-2 after:h-[1px] after:bg-gray-300':
                              idx === 0,
                          },
                          {
                            '-translate-y-[8px] opacity-0':
                              openIndex === -1 && !prefersReducedMotion,
                          },
                          {
                            'translate-y-0 opacity-100':
                              navItemIndex === openIndex &&
                              !prefersReducedMotion,
                          },
                        )}
                      >
                        {subNavItem.content && (
                          <Anchor
                            id={`subNavItem-${subNavItem.content}`}
                            className={cn(
                              'inline-block py-2 px-3 w-full',
                              'text-black hover:text-black font-bold text-[0.9375rem]',
                              'hover:bg-gray-300 focus:bg-gray-300 transition-colors duration-150 motion-reduce:transition-none',
                              {
                                'mt-4': idx === 1,
                              },
                            )}
                            href={subNavItem.url}
                          >
                            {subNavItem.content}
                          </Anchor>
                        )}
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </li>
          ))}
        </ul>
      )}
    </nav>
  );
}
