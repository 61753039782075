import cn from 'classnames';

import Anchor from '@/components/shared/Anchor/Anchor';
import {useTranslations} from '@/hooks/useTranslations';

interface Props {
  darkBackground: boolean;
}

export default function MobileMenuCtaLinklist({darkBackground}: Props) {
  const {t} = useTranslations();
  const contactText = t('plus:cta.contact');
  return (
    <>
      <li className="pt-1 sm:grow sm:pt-2">
        <Anchor
          className={cn('whitespace-nowrap hover:underline', {
            'text-link-dark': darkBackground,
            'text-link-light': !darkBackground,
          })}
          href="#contact-sales"
        >
          {contactText}
        </Anchor>
      </li>
    </>
  );
}
